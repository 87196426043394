/** ChatAppWidget
 * provides a simple button to "connect" with the user's chosen chatApp, or
 * if the user has already connected with a chatApp, provides a widget to show
 * the status of that connection and a way to reauthenticate, or disconnect the
 * app
 */
import { useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
	Typography,
	Box,
	Popover,
	CircularProgress,
	ClickAwayListener,
	List,
	ListItem,
	ListItemIcon,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import {
	CheckCircle,
	Cancel,
	ExpandMore,
	ExpandLess,
	Sync,
	Key,
} from '@mui/icons-material';
import { Slack } from '@common/assets/images/Slack';
import { RsButton } from '@common/components';
import { useChatApp, useAsync } from '@common/hooks';

/** Disabled
 * Button to show if the Org is not already connected to any ChatApp
 */
const Disabled = ({ config }) => {
	return (
		<RsButton
			component="a"
			variant="contained"
			href={config.oauth_url}
			sx={{
				display: 'inline-flex',
				minHeight: '3rem',
			}}
		>
			<Slack size="1.8rem" style={{ marginRight: '1rem' }} />
			Add to Slack
		</RsButton>
	);
};

/** EnabledPopover
 * Popover menu to show the current status of the connection and provide methods
 * for reauthentication or disconnecting the app
 */
const EnabledPopover = ({ anchorRef, widgetOpen, handleWidgetClose }) => {
	const theme = useTheme();
	const { team, config, disconnectApp } = useChatApp();
	const requiredScopes = config.scopes;
	const authorizedScopes = team.scope.split(',');
	const reAuthRequired = requiredScopes.length > authorizedScopes.length;
	const [disconnect, triggerDisconnect] = useAsync(disconnectApp);

	const handleTokenRefresh = () => {
		window.location.replace(config.oauth_url);
	};
	const handleDisconnect = () => {
		triggerDisconnect(config.chatId, team.id);
	};

	return (
		<Popover
			id="workspaceMenu"
			anchorEl={anchorRef.current}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={widgetOpen}
			onClose={handleWidgetClose}
			sx={{
				'> .MuiPaper-root': {
					borderRadius: '0.25rem',
				},
				'color': 'primary.surfaceText',
			}}
		>
			<ClickAwayListener onClickAway={handleWidgetClose}>
				<List
					sx={{
						bgcolor: 'background.paper',
						borderRadius: '0.25rem',
						boxShadow: theme.shadows[4],
						minHeight: '3rem',
						minWidth: '200px',
						width: '100%',
					}}
				>
					<ListItemButton onClick={handleWidgetClose}>
						<ListItemIcon>
							{config.chatId === 'slack' ? (
								<Slack size="1.8rem" />
							) : (
								<CheckCircle color="success" />
							)}
						</ListItemIcon>
						<ListItemText
							primary={`${team.team.name}`}
							sx={{
								pr: 8,
							}}
						/>
						<ExpandLess />
					</ListItemButton>
					<ListItem divider>
						<ListItemIcon>
							<CheckCircle color="success" />
						</ListItemIcon>
						<Box>
							<Typography
								variant="subtitle1"
								sx={{
									display: 'block',
									textTransform: 'uppercase',
									fontSize: '0.8rem',
									color: theme.palette.primary.main,
								}}
							>
								status
							</Typography>
							<Typography color="success">Connected</Typography>
						</Box>
					</ListItem>
					{config && (
						<ListItem divider alignItems="flex-start">
							<ListItemIcon>
								<Key
									color={reAuthRequired ? 'error' : 'success'}
								/>
							</ListItemIcon>
							<Box>
								<Typography
									variant="subtitle1"
									sx={{
										display: 'block',
										textTransform: 'uppercase',
										fontSize: '0.8rem',
									}}
								>
									Authorized scopes
								</Typography>
								<Typography variant="subtitle2">
									{authorizedScopes.length}
									{' of '}
									{requiredScopes.length}
									{' Permissions'}
								</Typography>
							</Box>
						</ListItem>
					)}
					<ListItemButton
						divider
						onClick={handleTokenRefresh}
						sx={{
							backgroundColor: reAuthRequired
								? theme.palette.error.surface
								: 'transparent',
							color: reAuthRequired
								? theme.palette.error.main
								: theme.palette.common.light,
						}}
					>
						<ListItemIcon>
							<Sync />
						</ListItemIcon>
						<ListItemText
							primary="Re-Authenticate"
							secondary="Update permissions"
						/>
					</ListItemButton>
					<ListItemButton
						onClick={handleDisconnect}
						dense={true}
						sx={{ color: 'error.main' }}
					>
						<ListItemIcon>
							{disconnect.loading ? (
								<CircularProgress size="1rem" />
							) : (
								<Cancel color="error" />
							)}
						</ListItemIcon>
						<ListItemText primary="Disconnect" />
					</ListItemButton>
				</List>
			</ClickAwayListener>
		</Popover>
	);
};

/** Enabled
 * Widget to show if the Org is already connected to a ChatApp
 * Includes a popover menu to show the current status of the connection and
 * provide options to reauthenticate or disconnect
 */
const Enabled = ({ team, config }) => {
	const [widgetOpen, setWidgetOpen] = useState(false);
	const anchorRef = useRef();

	const handleWidgetToggle = () => setWidgetOpen((prev) => !prev);
	const handleWidgetClose = () => setWidgetOpen(false);

	return (
		<>
			<RsButton
				variant="contained"
				startIcon={
					config.chatId === 'slack' ? (
						<Slack size="1.8rem" />
					) : (
						<CheckCircle color="success" />
					)
				}
				endIcon={<ExpandMore />}
				sx={{
					// background: `${theme.palette.primary.surface}`,
					minHeight: '3rem',
					minWidth: '30px',
				}}
				ref={anchorRef}
				onClick={handleWidgetToggle}
			>
				<Box
					textAlign="left"
					sx={{
						mx: 2,
						display: { xxs: 'none', sm: 'block' },
					}}
				>
					<Typography
						variant="subtitle1"
						noWrap
						sx={{
							textTransform: 'uppercase',
							fontSize: '0.7rem',
							display: 'block',
							m: 0,
							fontWeight: 500,
							lineHeight: 1,
							opacity: 0.6,
						}}
					>
						Connected
					</Typography>
					<Typography
						variant="body2"
						noWrap
						sx={{
							m: 0,

							lineHeight: 1.3,
						}}
					>
						{team.team.name}
					</Typography>
				</Box>
			</RsButton>
			<EnabledPopover
				anchorRef={anchorRef}
				widgetOpen={widgetOpen}
				handleWidgetClose={handleWidgetClose}
			/>
		</>
	);
};

export const ChatAppWidget = () => {
	const { team, config } = useChatApp();
	if (team && config) {
		return <Enabled team={team} config={config} />;
	}
	return <Disabled config={config} />;
};
