import { useChatApp } from '@common/hooks';
import { ChatAppWidget } from './ChatAppWidget';
import { Enabled } from './Enabled';
import { Disabled } from './Disabled';
import { ContentHeader } from '@app/layouts';
import { Typography } from '@mui/material';
import { textTools } from '@common/utils';

export const ConnectDashboard = () => {
	log('ConnectDashboard - render');
	const { team } = useChatApp();

	const introText = (
		<Typography variant="h5">
			Build <b>Incredible Team Connections</b> through fun activities,
			challenges, celebrations, and team rituals right within your Slack
			or Microsoft&nbsp;Teams&nbsp;workspace.
		</Typography>
	);

	return (
		<>
			<ContentHeader
				title={
					team?.chatId ? textTools.capitalize(team.chatId) : 'Connect'
				}
				menu={<ChatAppWidget />}
				content={introText}
			/>
			{team ? <Enabled /> : <Disabled />}
		</>
	);
};
