import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import { useAppTheme } from './appConfig';
import { useAppSettingsUpdater } from '@common/hooks';
import { AuthRoute, OrgRoute } from '@common/routes';
import { NoMatch, LogoAnimated } from '@common/components';

import { CreateOrg } from './routes/org';
import {
	Connect,
	// Home,
	// Toolkit,
	// Games,
	// Hosted,
	ComingSoon,
} from './routes/dashboard';
import { SlackRedirect } from './routes/slack';

import { DashboardLayout2, FullScreenLayout } from './layouts';

function App() {
	const appName = 'Dashboard';
	const theme = useAppTheme();
	const { setAppName } = useAppSettingsUpdater();

	useEffect(() => {
		setAppName(appName);
	}, [setAppName]);

	// log('App.js render');

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					{/* Org Routes (org && auth present) */}
					<Route element={<OrgRoute />}>
						<Route element={<DashboardLayout2 />}>
							<Route index element={<Navigate to="/connect" />} />
							<Route
								index
								path="/connect/*"
								element={<Connect />}
							/>
							<Route path="/toolkit/*" element={<ComingSoon />} />
							<Route path="/games/*" element={<ComingSoon />} />
							<Route path="/hosted/*" element={<ComingSoon />} />
							<Route path="/people/*" element={<ComingSoon />} />
							<Route
								path="/analytics/*"
								element={<ComingSoon />}
							/>
							<Route
								path="/settings/*"
								element={<ComingSoon />}
							/>
							<Route path="/billing/*" element={<ComingSoon />} />
						</Route>
					</Route>
					{/* Auth Routes (auth present) */}
					<Route element={<AuthRoute />}>
						<Route path="/create" element={<CreateOrg />} />
					</Route>
					{/* Any NGROK handler routes */}
					<Route element={<OrgRoute />}>
						<Route
							path="/slack/redirect"
							element={
								<FullScreenLayout>
									<SlackRedirect />
								</FullScreenLayout>
							}
						/>
					</Route>
					{/* catch all routes */}
					<Route element={<FullScreenLayout />}>
						<Route path="/loading" element={<LogoAnimated />} />
						<Route path="/*" element={<NoMatch />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
