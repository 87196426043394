import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@common/firebase';
import {
	useCurrentUser,
	useAsync,
	useRsNavigate,
	useChatApp,
} from '@common/hooks';
import { Loading, ErrorPanel } from '@common/components';
import { useTheme } from '@mui/material/styles';

export const SlackRedirect = () => {
	const theme = useTheme();
	const { navigateTo } = useRsNavigate();
	const { config } = useChatApp();
	const { loading, user } = useCurrentUser();
	const [searchParams] = useSearchParams();
	const [error, setError] = useState(false);
	const exchangeCode = httpsCallable(functions, 'platform-chats-slack-oauth');
	const [exchangeAuthCode, triggerExchangeAuthCode] = useAsync(exchangeCode);

	/** fetch the state and code params from the URL search string */
	const state = searchParams.get('state');
	const code = searchParams.get('code');

	/**
	 * In order to verify this callback response from Slack we need to check:
	 * 1. There is a code and state param supplied.
	 * 2. The state param [uid-orgId] matches the user's uid and currentOrg.id.
	 * 3. If the uid does not match, we need to refuse the request with an error
	 * 4. If the uid matches, but the orgId does not, we need to check the orgs
	 * and potentially shift the user into the correct orgId, or show an error.
	 * 5. Finally we need to process the code and state params by sending to the
	 * server to be exchanged for an access token that will be stored on the
	 * currentOrg property within the org record.
	 */

	/** onInit */
	useEffect(() => {
		log('SlackRedirect', { user, state, code, config });
		/** user is still loading */
		if (loading || !config.redirect_uri) return;

		if (!user) {
			setError('Missing user');
			return;
		}

		/** verify there is a code param on the URL */
		if (!code) {
			setError('Missing "code" param');
			return;
		}

		/** verify there is a state param on the URL */
		if (!state) {
			setError('Missing "state" param');
			return;
		}

		/** verify the config parameter is loaded */
		if (!config) {
			setError('Missing config');
		}

		/** verify the state param uid component matches the current user uid */
		const [uid, orgId] = state.split('-');
		const currentOrg = user?.currentOrg;
		if (uid !== user.uid) {
			setError('State uid does not match current user uid');
			return;
		}
		if (!currentOrg) {
			setError('Missing user.currentOrg');
			return;
		}

		/** check the state param org component matches the currentOrg.id  */
		if (orgId !== currentOrg.id) {
			/**
			 * here we can perform more actions to swap to the correct org
			 * if required, but for now we'll just show an error
			 */
			setError('State orgId does not match currentOrg.id');
			return;
		}

		/** remove the errors if we made it this far without issue */
		setError(false);

		/**
		 * code and state params are present and valid we can
		 * forward the code to the server to be exchanged for an action token
		 */

		/** don't call the api if it's already in progress or completed */
		if (!exchangeAuthCode.loading && !exchangeAuthCode.response) {
			log('calling oauthCallback...', {
				code,
				state,
				redirect_uri: config.redirect_uri,
			});
			triggerExchangeAuthCode({
				code,
				state,
				redirect_uri: config.redirect_uri,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, state, code, config, triggerExchangeAuthCode]);

	/** on exchangeAuthCode update */
	useEffect(() => {
		log('exchangeAuthCode:', exchangeAuthCode);
		const { loading, response, error } = exchangeAuthCode;
		if (loading) {
			return;
		}
		if (error) {
			console.error('exchangeAuthCode.error:', error);
			setError(error.message);
		}
		if (response) {
			log('exchangeAuthCode.response: ', response);
			navigateTo('/connect');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exchangeAuthCode]);

	return (
		<>
			{error ? (
				<ErrorPanel error={error} redirect={`/connect`} />
			) : (
				<Loading
					showRandom={true}
					label="Connecting to Slack..."
					color={theme.palette.primary.surface}
				/>
			)}
		</>
	);
};
