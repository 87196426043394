import { useChatApp } from '@common/hooks';
import { RsButton } from '@common/components';
import { Card, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { Slack } from '@common/assets/images/Slack';

const SlackTile = ({ authURL }) => {
	const theme = useTheme();
	log('theme', theme);
	return (
		<Card
			sx={{
				p: 4,
				minWidth: 220,
				maxWidth: 350,
				background: theme.palette.primary.gradient2,
				color: 'primary.contrastText',
				textAlign: 'center',
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: 'primary.altOutline',
			}}
			variant="outlined"
		>
			<Typography variant="h4" sx={{ mb: theme.spacing(3) }}>
				Slack
			</Typography>
			{authURL && (
				<Typography>
					<RsButton
						component="a"
						style={{
							alignItems: 'center',
							backgroundColor: theme.palette.primary.surface,
							color: theme.palette.primary.dark,
							fontWeight: 'bold',
							height: '3.5rem',
							width: '100%',
							justifyContent: 'center',
							textDecoration: 'none',
						}}
						variant="contained"
						href={authURL}
					>
						<Slack
							style={{ height: '2rem', marginRight: '0.5rem' }}
						/>
						Add to Slack
					</RsButton>
				</Typography>
			)}
		</Card>
	);
};

const TeamsTile = ({ authURL }) => {
	const theme = useTheme();
	return (
		<Card
			sx={{
				p: 4,
				minWidth: 220,
				maxWidth: 350,
				background: theme.palette.neutral.altSurface,
				color: 'common.main',
				textAlign: 'center',
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: 'common.main',
			}}
			variant="outlined"
			disabled
		>
			<Typography variant="h4" sx={{ mb: theme.spacing(3) }}>
				Microsoft Teams
			</Typography>
			<Typography>
				<RsButton
					disabled
					variant="contained"
					style={{
						alignItems: 'center',

						fontWeight: 600,
						height: '3.5rem',
						width: '100%',
						justifyContent: 'center',
						textDecoration: 'none',
					}}
				>
					Teams coming soon
				</RsButton>
			</Typography>
		</Card>
	);
};

export const Disabled = () => {
	const { config } = useChatApp();
	return (
		<>
			<Typography variant="h3" sx={{ mb: 8 }}>
				Create incredible team connections
			</Typography>
			<Typography variant="body1" sx={{ mb: 8 }}>
				Connect Remote Social with Slack or Microsoft Teams to run{' '}
				<b>team challenges</b>, help <b>celebrate</b> events,{' '}
				<b>onboard</b> new-starters, and get <b>conversations</b> going.
			</Typography>
			<Typography variant="h4" sx={{ mb: 8 }} align="center">
				Choose your messaging platform to get started.
			</Typography>
			<Grid container spacing={4} sx={{}}>
				<Grid
					xxs={12}
					md={6}
					sx={{
						justifyContent: { xxs: 'center', md: 'flex-end' },
						display: 'flex',
					}}
				>
					<SlackTile authURL={config.oauth_url} />
				</Grid>
				<Grid
					xxs={12}
					md={6}
					sx={{
						justifyContent: { xxs: 'center', md: 'flex-start' },
						display: 'flex',
					}}
				>
					<TeamsTile />
				</Grid>
			</Grid>
			<Typography variant="emoji" sx={{ my: 8 }}>
				😍
			</Typography>
		</>
	);
};
