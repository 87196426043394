import { useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useRsNavigate, useChatApp } from '@common/hooks';
import { ConnectDashboard } from '@app/components/connect';
import { ManageActivity } from '@app/components/connect/ManageActivity';
import {
	Wordle,
	WordleCreate,
	Gratituesday,
	GratituesdayCreate,
} from '@app/components/connect/activities';

/** ConnectRoute
 * Tests the useChatApp status for a valid team value. If missing, we will
 * redirect the user to the homepage of the Connect dashboard to establish
 * a connection to a chatApp team.
 *
 * Otherwise we'll render the activity sub-pages.
 */
const ConnectRoute = ({ children }) => {
	// log('ConnectRoute - render');
	const { team } = useChatApp();
	const { navigateTo } = useRsNavigate();

	useEffect(() => {
		if (!team) {
			log('🔀 ConnectRoute: ✅ team is missing, redirect');
			navigateTo('/connect', { replace: true });
			return;
		}
	}, [team, navigateTo]);

	if (!team) return <></>;
	return children ? children : <Outlet />;
};

/** Router for the Connect section of the dashboard */
export const Connect = () => {
	return (
		<Routes>
			<Route index element={<ConnectDashboard />} />
			<Route element={<ConnectRoute />}>
				<Route path="/wordle" element={<Wordle />} />
				<Route path="/wordle/create" element={<WordleCreate />} />
				<Route path="/gratituesday" element={<Gratituesday />} />
				<Route
					path="/gratituesday/create"
					element={<GratituesdayCreate />}
				/>
				<Route
					path="/activity/:activityId"
					element={<ManageActivity />}
				/>
			</Route>
		</Routes>
	);
};
